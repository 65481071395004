import logoImage from './assets/img/logo.png';
// import headerImage from './assets/img/header.jpg';
import headerImage from './assets/img/header-kari.jpg';
import cartImage from './assets/img/kosar-kari.jpg';
import lastMinuteImage from './assets/img/last-minute.png';
import fontFamily from './assets/fonts/OpenSans-Regular.ttf';

(window as any).config = {
    uid: 'cityfood',
    logoImage: logoImage,
    headerImage: headerImage,
    cartImage: cartImage,
    lastMinute: {
        text: 'A következő választékunk rendelhető a készlet erejéig (Fontos, hogy a Last minute során megrendelt ételek lemondására nincs lehetőség. Kérjük, fokozott figyelemmel adjanak le rendelést és válasszanak fizetési módot!): A betűjel végén szereplő „Z” kis adagot jelöl. Szombati ételeink pénteken kerülnek kiszállításra.',
        image: lastMinuteImage
    },
    projectName: 'Cityfood',
    projectSlogan: 'Több mint ebéd. Élmény! - Minden jog fenntartva',
    loginWelcome: 'Üdv újra a Cityfood weblapján, kérem jelentkezzen be!',
    pdf: {
        fontFamily: fontFamily
    },
    constans: {
        CONST_URL_FOOLDAL: 'https://www.cityfood.hu/',
        CONST_URL_GYIK: 'https://www.cityfood.hu/gyakran-ismetelt-kerdesek/',
        CONST_URL_KAPCSOLAT: 'https://www.cityfood.hu/kapcsolat/',
        CONST_URL_TUDNIVALOK: 'https://www.cityfood.hu/tudnivalok/',
        CONST_URL_SZALLITASI_TERULETEK: 'https://rendel.cityfood.hu/delivery-areas/',
        CONST_URL_ADATVEDELMI:
            'https://www.cityfood.hu/wp-content/uploads/2018/04/Adatvedelmi_szabalyzat_Cityfood.pdf',
        CONST_URL_ASZF: 'https://www.cityfood.hu/altalanos-szerzodesi-feltetelek/',
        CONST_URL_ADATKEZELES: 'https://ca.cityfood.hu/adatkezeles',
        CONST_URL_VISSZAELES_BEJELENTES:
            'https://app.cityfood.hu/visszaeles-bejelentesi-szabalyzat',
        CONST_URL_BLOG: 'https://www.cityfood.hu/blog/'
    },
    urls: {
        prod: 'https://ca.cityfood.hu',
        dev: 'https://city.dev.diff.hu',
        local: 'http://foodgroup-laravelbp.localhost'
    },
    bugsnag: {
        api: 'a074057a802dd1df8615e2298ee1217e'
    },
    sentry: {
        dns: 'https://3e2efcd0c60a57a35f9d2974251b20f3@o1038507.ingest.us.sentry.io/4508284143075328'
    },
    payment_methods: ['cash', 'barion', 'mkb_szep_card', 'otp_szep_card', 'kh_szep_card'],
    hasMultilingualMenu: true
};

import './variables.scss';
import('../../src/main')
    .then(module => {
        // The module is now loaded and executed
        console.log(`[${window.config?.uid ?? ''}] Main module loaded!`);
    })
    .catch(error => {
        console.error('❌ Error loading main module:', error);
    });
